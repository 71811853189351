import { Injectable, Signal } from '@angular/core';
import { Observable } from 'rxjs';
import { LegacyAuthService } from './legacy/legacy-auth.service';
import { UserPermission } from './permissions/user-permissions';
import { User, UserStatus } from './user';

export interface SignUpData {
  email: string;
  occupationId: number;
  fullname: string;
  password: string;
  gdprAccepted: boolean;
  newsletterAccepted: boolean;
  bar?: string | null;
  promoCode?: string | null;
  cloudflareTurnstileToken: string;
}

@Injectable({
  providedIn: 'root',
  useExisting: LegacyAuthService,
})
export abstract class AuthService {
  abstract readonly isAuthenticated: Signal<boolean>;
  abstract readonly user: Signal<User | null | undefined>;

  abstract getCurrentUser(): Observable<User | null>;
  abstract currentUserHasPermission(permission: UserPermission): boolean;
  abstract currentUserHasSomePermissions(
    permissions: UserPermission[],
  ): boolean;
  abstract currentUserHasStatus(...statuses: UserStatus[]): boolean;
  abstract sendVerificationCodeBySMS(phoneNumber: string): Observable<void>;
  abstract sendVerificationCodeByEmail(email: string): Observable<void>;
  abstract checkAccount(code: string): Observable<void>;
  abstract signIn(
    email: string,
    password: string,
    cloudflareTurnstileToken: string,
  ): Observable<string>;
  abstract signInWithToken(token: string): void;
  abstract signInByIPAddress(): Observable<string | null>;
  abstract signUp(data: SignUpData): Observable<User>;
  abstract askForNewPassword(email: string): Observable<void>;
  abstract logout(): void;
}
